
import { defineComponent } from 'vue'
import user from '../utils/user'
import { CompanyItemDto, getCompanyListApi, replaceCompany } from '@/api/alipay-recharge'
import global from '@/utils/global'

export default defineComponent({
    name: 'Login',
    data() {
        return {
            companyList: [] as CompanyItemDto[],
            showcompany: false,
            userName: '',
            password: '',
            verityCode: '',
            isStorage: false,
            verityImg: '',
            uuid: '',
            isQr: false
        }
    },

    watch: {
        async $route(to, from) {
            console.log(to.query, 'jdsgujisiuj')
            if (to.query.isNext && to.query.code) {
                this.showcompany = true
                let commonlyList = await user.codeLogin(to.query.code)
                this.companyList = commonlyList as CompanyItemDto[]
            }
        }
    },
    async created() {
        // start 2024.10.17 微信登录授权跳转的redirect_uri路径变掉了，类似于https://yy.51zidan.com/?code=091x4Zll2J7Gne4cO7ol2nsYnK2x4Zlu&state=saas#/login，导致路由里变化里监听不到变化无法调用接口，所以加了这个判断操作
        const href = window.location.href
        if (href.endsWith('state=saas#/login') && href.includes('code=')) {
            const querystr = href.split('?')[1]
            const codestr = querystr.split('&')[0]
            const code = codestr.split('=')[1]
            if (code) {
                this.showcompany = true
                let commonlyList = await user.codeLogin(code)
                this.companyList = commonlyList as CompanyItemDto[]
                // window.open('https://web.51zidan.com/', '_self')
                // this.handleRedirectTo()
            }
        }
        // end
        if (localStorage.userName) {
            this.userName = localStorage.userName
            this.password = localStorage.password
            this.isStorage = true
        }
        this.getCode()
    },
    methods: {
        async sureLogin(id: string | number, phone: string | number) {
            await user.switchLogin({
                username: this.isQr ? phone : this.userName,
                companyId: id
            })
            await this.handleRedirectTo()
        },
        async back() {
            this.showcompany = false
        },
        async getCode() {
            const photo = await user.getPhoto()
            this.verityImg = 'data:image/jpg;base64,' + photo.img
            this.uuid = photo.uuid
        },
        async onNextStep() {
            this.showcompany = true
            let commonlyList = await getCompanyListApi({ phone: this.userName })
            console.log(commonlyList, 'commonlyList')
            this.companyList = commonlyList as CompanyItemDto[]
        },
        async login() {
            await user
                .login({
                    username: this.userName,
                    password: this.password,
                    uuid: this.uuid,
                    code: this.verityCode
                })
                .catch(() => {
                    this.getCode()
                    this.verityCode = ''
                    throw new Error()
                })
            await this.onNextStep()
            // if (this.isStorage) {
            //     localStorage.userName = this.userName
            //     localStorage.password = this.password
            // } else if (localStorage.userName) {
            //     localStorage.removeItem('userName')
            //     localStorage.removeItem('password')
            // }
            // sessionStorage.setItem('tcflag', 'true')
            // sessionStorage.setItem('dayinsuretcflag', 'true')
            // this.handleRedirectTo()
        },
        qrLogin() {
            this.isQr = true
        },
        cancelQr() {
            this.isQr = false
        },
        handleRedirectTo() {
            if (user.isPcAdmin) {
                this.$router.push({
                    path: '/'
                })
            } else {
                this.$router.push({
                    path: '/pay'
                })
            }
        }
    }
})
